(function($){
    "use strict"; // Start of use strict

    $(window).on('load', function() {

        // Page loader
        $("body").imagesLoaded(function(){
            $(".page-loader div").fadeOut();
            $(".page-loader").delay(200).fadeOut("slow");
        });

        //grid isotope filtering
        initWorkFilter();

        init_scroll_navigate();
    });


    $(document).ready(function(){

        //two global objects
        window.mobile_nav = $(".mobile-nav");
        //console.log(mobile_nav);
        window.desktop_nav = $(".desktop-nav");
        //console.log(desktop_nav);

        $(window).trigger("resize");

        init_classic_menu();


        // Sections backgrounds
        var pageSection = $(".home-section, .page-section, .small-section, .split-section");
        //console.log(pageSection);

        pageSection.each(function(indx){
            if ($(this).attr("data-background")){
                // console.log('tuuuut');
                $(this).css("background-image", "url(" + $(this).data("background") + ")");
            }
        });

        init_parallax();

        // Progress bars
        var progressBar = $(".progress-bar");
        progressBar.each(function(indx){
            $(this).css("width", $(this).attr("aria-valuenow") + "%");
        });

    });

    $(window).resize(function(){

        init_classic_menu_resize();
        // init_side_panel_resize()
        js_height_init();
        // split_height_init();

    });


    /* --------------------------------------------
     Platform detect
     --------------------------------------------- */
    var mobileTest;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        mobileTest = true;
        $("html").addClass("mobile");
    }
    else {
        mobileTest = false;
        $("html").addClass("no-mobile");
    }

    var mozillaTest;
    if (/mozilla/.test(navigator.userAgent)) {
        mozillaTest = true;
    }
    else {
        mozillaTest = false;
    }
    var safariTest;
    if (/safari/.test(navigator.userAgent)) {
        safariTest = true;
    }
    else {
        safariTest = false;
    }

    // Detect touch devices
    if (!("ontouchstart" in document.documentElement)) {
        document.documentElement.className += " no-touch";
    }



    /* ---------------------------------------------
     Sections helpers
     --------------------------------------------- */

     // Sections backgrounds

    // var pageSection = $(".home-section, .page-section, .small-section, .split-section");
    // console.log(pageSection);
    // pageSection.each(function(indx){
    //
    //     if ($(this).attr("data-background")){
    //         console.log('tuuuut');
    //         $(this).css("background-image", "url(" + $(this).data("background") + ")");
    //     }
    // });

     // Function for block height 100%
     function height_line(height_object, height_donor){
         height_object.height(height_donor.height());
         height_object.css({
             "line-height": height_donor.height() + "px"
         });
     }


    /* ---------------------------------------------
     Nav panel classic
     --------------------------------------------- */



    function init_classic_menu_resize() {
        //console.log('init_classic_menu_resize');


        // Mobile menu max height
        $(".mobile-on .desktop-nav > ul").css("max-height", $(window).height() - $(".main-nav").height() - 20 + "px");

        // Mobile menu style toggle
        if ($(window).width() <= 1024) {
            $(".main-nav").addClass("mobile-on");
        }
        else {
            if ($(window).width() > 1024) {
                $(".main-nav").removeClass("mobile-on");
                desktop_nav.show();
            }
        }
    }

    function init_classic_menu() {
        //console.log('init_classic_menu');

        //console.log(mobile_nav);
        //console.log(desktop_nav);

        // Navbar sticky

        $(".js-stick").sticky({
            topSpacing: 0
        });


        height_line($(".inner-nav > ul > li > a"), $(".main-nav"));
        height_line(mobile_nav, $(".main-nav"));

        mobile_nav.css({
            "width": $(".main-nav").height() + "px"
        });

        // Transpaner menu

        if ($(".main-nav").hasClass("transparent")){
           $(".main-nav").addClass("js-transparent");
        }

        $(window).scroll(function(){

                if ($(window).scrollTop() > 10) {
                    $(".js-transparent").removeClass("transparent");
                    $(".main-nav, .nav-logo-wrap .logo, .mobile-nav").addClass("small-height");
                }
                else {
                    $(".js-transparent").addClass("transparent");
                    $(".main-nav, .nav-logo-wrap .logo, .mobile-nav").removeClass("small-height");
                }


        });

        // Mobile menu toggle

        mobile_nav.click(function(){

            if (desktop_nav.hasClass("js-opened")) {
                desktop_nav.slideUp("slow", "easeOutExpo").removeClass("js-opened");
                $(this).removeClass("active");
            }
            else {
                desktop_nav.slideDown("slow", "easeOutQuart").addClass("js-opened");
                $(this).addClass("active");

                // Fix for responsive menu
                if ($(".main-nav").hasClass("not-top")){
                    $(window).scrollTo(".main-nav", "slow");
                }

            }

        });

        desktop_nav.find("a:not(.mn-has-sub)").click(function(){
            if (mobile_nav.hasClass("active")) {
                desktop_nav.slideUp("slow", "easeOutExpo").removeClass("js-opened");
                mobile_nav.removeClass("active");
            }
        });


        // Sub menu

        var mnHasSub = $(".mn-has-sub");
        var mnThisLi;

        $(".mobile-on .mn-has-sub").find(".fa:first").removeClass("fa-angle-right").addClass("fa-angle-down");

        mnHasSub.click(function(){

            if ($(".main-nav").hasClass("mobile-on")) {
                mnThisLi = $(this).parent("li:first");
                if (mnThisLi.hasClass("js-opened")) {
                    mnThisLi.find(".mn-sub:first").slideUp(function(){
                        mnThisLi.removeClass("js-opened");
                        mnThisLi.find(".mn-has-sub").find(".fa:first").removeClass("fa-angle-up").addClass("fa-angle-down");
                    });
                }
                else {
                    $(this).find(".fa:first").removeClass("fa-angle-down").addClass("fa-angle-up");
                    mnThisLi.addClass("js-opened");
                    mnThisLi.find(".mn-sub:first").slideDown();
                }

                return false;
            }
            else {

            }

        });

        mnThisLi = mnHasSub.parent("li");
        mnThisLi.hover(function(){

            if (!($(".main-nav").hasClass("mobile-on"))) {

                $(this).find(".mn-sub:first").stop(true, true).fadeIn("fast");
            }

        }, function(){

            if (!($(".main-nav").hasClass("mobile-on"))) {

                $(this).find(".mn-sub:first").stop(true, true).delay(100).fadeOut("fast");
            }

        });

    }


    /* -------------------------------------------
     Parallax
     --------------------------------------------- */
    function init_parallax(){

        // Parallax
        if (($(window).width() >= 1024) && (mobileTest == false)) {
            $(".parallax-1").parallax("50%", 0.1);
            $(".parallax-2").parallax("50%", 0.2);
            $(".parallax-3").parallax("50%", 0.3);
            $(".parallax-4").parallax("50%", 0.4);
            $(".parallax-5").parallax("50%", 0.5);
            $(".parallax-6").parallax("50%", 0.6);
            $(".parallax-7").parallax("50%", 0.7);
            $(".parallax-8").parallax("50%", 0.5);
            $(".parallax-9").parallax("50%", 0.5);
            $(".parallax-10").parallax("50%", 0.5);
            $(".parallax-11").parallax("50%", 0.05);
        }

    }

})(jQuery); // End of use strict



/* ---------------------------------------------
 Portfolio section
 --------------------------------------------- */

// Projects filtering
// var fselector = 0;
// var work_grid = $("#work-grid, #isotope");
//
// console.log(work_grid);

function initWorkFilter(){
    console.log('initWorkFilter');

    var fselector = 0;
    var work_grid = $("#work-grid, #isotope");

    //console.log(work_grid);

    (function($){
     "use strict";
     var isotope_mode;
     if (work_grid.hasClass("masonry")){
         isotope_mode = "masonry";
     } else{
         isotope_mode = "fitRows"
     }

     $(".filter").click(function(){
         $(".filter").removeClass("active");
         $(this).addClass("active");
         fselector = $(this).attr('data-filter');

         work_grid.imagesLoaded(function(){
             work_grid.isotope({
                 itemSelector: '.mix',
                 layoutMode: isotope_mode,
                 filter: fselector
             });
         });
         return false;
     });

     if (window.location.hash) {
         $(".filter").each(function(){
             if ($(this).attr("data-filter") == "." + window.location.hash.replace("#", "")) {
                 $(this).trigger('click');

                 $("html, body").animate({
                     scrollTop: $("#portfolio").offset().top
                 });

             }
         });
     }

     work_grid.imagesLoaded(function(){
         work_grid.isotope({
             itemSelector: '.mix',
             layoutMode: isotope_mode,
             filter: fselector
         });
     });


    })(jQuery);
}


/* ---------------------------------------------
 Scroll navigation
 --------------------------------------------- */

function init_scroll_navigate(){

    $(".local-scroll").localScroll({
        target: "body",
        duration: 1500,
        offset: 0,
        easing: "easeInOutExpo"
    });

    // var sections = $(".home-section, .split-section, .page-section");
    // var menu_links = $(".scroll-nav li a");
    //
    // $(window).scroll(function(){
    //
    //     sections.filter(":in-viewport:first").each(function(){
    //         var active_section = $(this);
    //         var active_link = $('.scroll-nav li a[href="#' + active_section.attr("id") + '"]');
    //         menu_links.removeClass("active");
    //         active_link.addClass("active");
    //     });
    //
    // });

}


/* ---------------------------------------------
 Height 100%
 --------------------------------------------- */
function js_height_init(){
    //console.log('js_height_init');

    (function($){
        $(".js-height-full").height($(window).height());
        $(".js-height-parent").each(function(){
            $(this).height($(this).parent().first().height());
        });
    })(jQuery);
}